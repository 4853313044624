.label, .badge {
    text-transform: uppercase;
    color: white;
    font-weight: bold;
    font-size: 10px;
    border-radius: 5px;
    height: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: $green;
    padding: 5px 10px;
    overflow: hidden; 

    &.status- {
        &0, &5 {
            background-color: $blue;
        }
        &7{
            background-color: $pink;
        }
        &10, &20, &30  {
            background-color: $orange;
        }
        &100, &110, &120, &130, &140 {
            background-color: $dark-red;
        }
        &210, &230 {
            background-color: $green;
        }
        &200 {
            background-color: $purple;
        } 
        &300, &310, &340 {
            background-color: $gray-800;
        }
        &320, &330 {
            background-color: $pink;
        }
    } 
 
    &.cmdStatus- {
        &100 {
            background-color: $blue;
        }&150{
            background-color:$pink;
        }
        &200, &300, &310  {
            background-color: $orange;
        }
        &350, &400, &450, &500, &510, &520, &530, &540 {
            background-color: $dark-red;
        }
        &550, &650, &700, &710, &720 {
            background-color: $green;
        }
        &600{
            background-color: $purple;
        }
        &730, &740, &750, &800, &900 {
            background-color: $gray-800;
        }
        &810, &820, &830, &840 {
            background-color: $pink
        }
    }
    &-success {
        background-color: $green;
    }

    &-danger {
        background-color: $red;
    }
}

.label {
    width: 150px;
    white-space: pre-wrap;
    text-align: center;
    line-height: 1.3;
    height: auto;
}

.badge {
    width: 90px;
}