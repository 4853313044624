//Fonts
@import url('https://fonts.googleapis.com/css?family=Barlow');
@import url('https://fonts.googleapis.com/css?family=Barlow|Pacifico');

$font1:                             'Barlow', sans-serif;
$font2:                             'Pacifico', cursive;

//Colors
$primary: #BECD00;
$secondary: #363740;
$tertiary: #F0F1F7;
$success: #28a745;
$bgColor:                           #ededed;

$textNormal: #575757;


$navbar-icon-toggler-color:         #ffffff;

$theme-colors: (
  "primary":    $primary,
);