h1 {
    font-size: 3.5rem !important;
    font-weight: 600 !important;
    margin-bottom: 0 !important;
}

h2 {
    font-size: 2.5rem !important;
    font-weight: 600 !important;
    margin-bottom: 0 !important;
}

h3 {
    font-size: 2.5rem !important;
    font-weight: 400 !important;
    margin-bottom: 0 !important;
}

h4 {

    margin-bottom: 0 !important;
}

h5 {

    margin-bottom: 0 !important;
}

h6 {

    margin-bottom: 0 !important;
}

p {
    font-size: 1.6rem !important;
    margin-bottom: 0 !important;
}