.form-check {
	margin-bottom: 1rem;
}
.mediaField {
	position: absolute;
	left: -100000px;
	visibility: hidden;
}

input[type='text'], input[type='password'] /* input[type='number']  */{
	background-color: #{$tertiary}B3;
	height: 45px;
	border-radius: 8px;
	border: 2px solid transparent;
	padding: 18px 23px;
	font-size: 1.4rem;

	&:focus {
		background-color: transparent;
		border-color: $tertiary;
	}
}
