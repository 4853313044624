.logout-btn {
    display: flex;
    padding: 10px;
    border-radius: 10px;
    width: 31px;
    height: 31px;
    margin-right: 33px;
    margin-left: 30px;
}

.userName {
    margin: 0 20px 0 0;
}