
// core overrides

$border-color:                     $gray-300;

// Navbar

$navbar-brand-width:                230px;
$navbar-brand-logo-size:            80px auto;

// Sidebar

$sidebar-width:                     230px;
$closed-sidebar-width:               50px;
$mobile-sidebar-width:              230px;

// Sidebar Navigation

$sidebar-bg:                        #363740 !default;
$sidebar-nav-link-hover-bg:         $secondary;
$sidebar-nav-link-active-bg:        transparent;
$sidebar-nav-dropdown-bg:           $secondary;

// Breadcrumb

$breadcrumb-borders:                0;

