@each $color, $value in $theme-colors {
  .modal-#{$color} {

    .modal-content {
      border-color: $value;
    }

    .modal-header {
      color: #fff;
      background-color: $value;
    }
  }
}


.modal-dialog {
  max-width: 667px;
}

.modal-content {
  border-radius: 8px;

  .modal-header {
    border: none;
    justify-content: center;
    align-items: center;
    padding-bottom: 80px;
    position: relative;

    .modal-title {
      font-size: 4rem;
      font-weight: 700;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }


    .close {
      line-height: 100%;

      span {
        font-size: 33px;
      }
    }
  }

  .modal-body {
    padding: 0 85px 20px 85px;
    border: none;
  }

  .modal-footer {
    border: none;
    padding: 0 85px 35px 85px;
  }

}
