// Here you can add other styles
.navbar {
	.form-inline {
		i {
			color: $navbar-color;
		}
		.form-control {
			min-width: 250px;
			color: $navbar-color;
			border: 0;

			&::placeholder {
				color: $navbar-color;
			}
		}
		.form-control::-webkit-input-placeholder {
			color: $navbar-color;
		}
		.form-control:-moz-placeholder {
			color: $navbar-color;
		}
		.form-control::-moz-placeholder {
			color: $navbar-color;
		}
		.form-control:-ms-input-placeholder {
			color: $navbar-color;
		}
	}
}

.sidebar {
	background-color: $sidebar-bg;
	color: white;
	
	.sidebar-header {
		background-color: #0066b1;
		padding: 20px;
		.img-avatar {
			width: 80px;
			margin: 20px auto 10px;
			border-radius: 50em;
		}

		> .btn-group {
			margin-top: 10px;
		}

		.btn-link {
			color: $text-muted;

			&:hover {
				color: $sidebar-color;
				text-decoration: none;
			}
		}
		a {
			color: white;
		}

		.nav-link {
			a {
				color: #0066b1;
			}
		}
	}
}

.card-header .card-actions a,
.card-header .card-actions button {
	display: block;
	float: left;
	width: auto;
	min-width: 50px;
	padding: 0.75rem 0.75rem;
	margin: 0 !important;
	text-align: center;
	border: 0;
	border-left: 1px solid #d1d4d7;
	box-shadow: 0;
}

.breadcrumb {
	background: #fff;
	border-bottom: 1px solid #e3e8ec;
	margin-bottom: 1.5rem;
}

.jumbotron {
	padding: 0;
}

.list-group-item {
	text-align: left;
}

.field-info {
	display: flex;
	flex-direction: row;
	padding: 5px 0;

	.label {
		width: 200px;
	}

	.value {
		flex: 1;
	}
}

.form-horizontal.form-quote {
	.form-group {
		input[type="number"] {
			max-width: 200px;
		}
	}

	.card-title {
		margin: 1rem 0;
		border-bottom: solid 1px #d1d4d7;
		padding: 1rem 0;
	}
}

//A sortir dans une feuille dédié à la commnande
.detail-financement {
	font-size: 14px;

	.row {
		padding: 10px 0;
	}

	.col-md-4 {
		text-align: right;
	}
}

.form-vertical {
	.form-group {
		flex-direction: column;

		label {
			font-size: 1.6rem;
			font-weight: 600;
		}
		
	}
}
.form-group {
	label {
		font-size: 1.6rem;
		font-weight: 600;
	}
}

.DateInput_input {
	padding: 7px 12px 7px !important;
}

.react-bootstrap-daterangepicker-container {
	display: block !important;
}

.select2Search {
	.Select-control {
		border-radius: 0;
		height: 44px;

		.Select-multi-value-wrapper {
			font-size: 20px;
		}
	}
}

.aside-menu {
	.list-group {
		.list-group-item {
			border: none;
			border-bottom: 1px solid rgba(0, 0, 0, 0.125);
			margin-bottom: 0;
		}
	}
}

@media (min-width: 992px) {
	.modal-lg {
		max-width: 90%;
	}
}

/*
 * Glyphicons
 *
 * Special styles for displaying the icons and their classes in the docs.
 */

.bs-glyphicons {
	overflow: hidden;
}
.bs-glyphicons-list {
	padding-left: 0;
	list-style: none;
}
.bs-glyphicons li {
	float: left;
	width: 50%;
	height: 115px;
	padding: 10px;
	font-size: 10px;
	line-height: 1.4;
	text-align: center;
	background-color: #f9f9f9;
	border: 1px solid #fff;
}
.bs-glyphicons .glyphicon {
	margin-top: 5px;
	margin-bottom: 10px;
	font-size: 24px;
}
.bs-glyphicons .glyphicon-class {
	display: block;
	text-align: center;
	word-wrap: break-word; /* Help out IE10+ with class names */
}
.bs-glyphicons li:hover {
	color: #fff;
	background-color: #563d7c;
}

@media (min-width: 768px) {
	.bs-glyphicons {
		margin-right: 0;
		margin-left: 0;
	}
	.bs-glyphicons li {
		width: 25%;
		font-size: 12px;
	}
}

.react-toggle {
	touch-action: pan-x;
	display: inline-block;
	position: relative;
	cursor: pointer;
	background-color: transparent;
	border: 0;
	padding: 0;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	-webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

.react-toggle--disabled {
	cursor: not-allowed;
	opacity: 0.5;
	-webkit-transition: opacity 0.25s;
	transition: opacity 0.25s;
}

.react-toggle-track {
	width: 50px;
	height: 24px;
	padding: 0;
	border-radius: 30px;
	background-color: #4d4d4d;
	-webkit-transition: all 0.2s ease;
	-moz-transition: all 0.2s ease;
	transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
	background-color: #000000;
}

.react-toggle--checked {
	.react-toggle-track {
		background-color: #19ab27;
	}
	&:hover:not(.react-toggle--disabled) .react-toggle-track {
		background-color: #128d15;
	}
}

.react-toggle-track-check {
	position: absolute;
	width: 14px;
	height: 10px;
	top: 0px;
	bottom: 0px;
	margin-top: auto;
	margin-bottom: auto;
	line-height: 0;
	left: 8px;
	opacity: 0;
	-webkit-transition: opacity 0.25s ease;
	-moz-transition: opacity 0.25s ease;
	transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
	opacity: 1;
	-webkit-transition: opacity 0.25s ease;
	-moz-transition: opacity 0.25s ease;
	transition: opacity 0.25s ease;
}

.react-toggle-track-x {
	position: absolute;
	width: 10px;
	height: 10px;
	top: 0px;
	bottom: 0px;
	margin-top: auto;
	margin-bottom: auto;
	line-height: 0;
	right: 10px;
	opacity: 1;
	-webkit-transition: opacity 0.25s ease;
	-moz-transition: opacity 0.25s ease;
	transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
	opacity: 0;
}

.react-toggle-thumb {
	transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
	position: absolute;
	top: 1px;
	left: 1px;
	width: 22px;
	height: 22px;
	border: 1px solid #4d4d4d;
	border-radius: 50%;
	background-color: #fafafa;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-transition: all 0.25s ease;
	-moz-transition: all 0.25s ease;
	transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
	left: 27px;
	border-color: #19ab27;
}

.react-toggle--focus .react-toggle-thumb {
	-webkit-box-shadow: 0px 0px 3px 2px #0099e0;
	-moz-box-shadow: 0px 0px 3px 2px #0099e0;
	box-shadow: 0px 0px 2px 3px #0099e0;
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
	-webkit-box-shadow: 0px 0px 5px 5px #0099e0;
	-moz-box-shadow: 0px 0px 5px 5px #0099e0;
	box-shadow: 0px 0px 5px 5px #0099e0;
}

input[type="color"] {
	height: 50px;
}


.login {

	&Container {
		background-image: url('../assets/login-bg.svg');
		background-size: contain;
		background-repeat: no-repeat;
		background-position: bottom center;
	}

	&Header {
	position: relative;
	background-color: white;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-bottom: 25px;

		img { 
			padding: 15px;
			margin-bottom: 35px;
		}

		h1 {
			font-size: 6.5rem !important;
		}
	}

	&Body {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		form {
			width: 300px;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;
		}
	
	}
}

.rc-progress-line  {
	margin-bottom: 50px;
	margin-top: 20px;
}

.form-control {
	font-size: 1.4rem;
}

.dashMarg {
	margin-bottom: 35px;
	margin-top: 30px;
	border-radius: 20px;
}

.divIndicator{
	background: #F0F1F7 0% 0% no-repeat padding-box;
	box-shadow: 0px 1px 4px #15223214;
	border-color: #707070;
	border-radius: 6px;
	opacity: 1;
	top: 205px;
	left: 483px;
	width: 303px;
	height: 90px;
	margin-left : 40px;
	margin-right: 30px;
	margin-bottom : 30px;
	text-align: center;
}

.fontEuro{
	font: bold 30px/46px Barlow;
	color:  #363740;
}

.fontNumber{
	text-align: left;
	letter-spacing: 0.12px;
	color: #363740;
	opacity: 1;
}

.cursor{
	cursor: pointer;
}

.buttonRound{
	width: 30px;
	height: 30px;
	border-radius: 20px;
	border-style: none;
}

.refButton{
	background: #F0F1F7 0% 0% no-repeat padding-box;
	border-color: #707070;
	float: right;
	border-radius: 6px;
	border-color: transparent;
}

.modalDash {
	text-align: center;
    display: block;
    left: 60%;
    height: 45%;
    width: 30%;
    overflow-y:auto;
    overflow-x:auto;
    border-radius: 10px;
	background-color:#fffefe;
	border-style: groove;
	border-width: 0.2px;
	border-color: #3d3d3d;
	padding: 10px;
    position: absolute;
    top: 50%;
    transform: translate(-70%, -50%);
}
 
.marg{
	margin-top: 10px;
}

.margeResp{
	@media screen and (max-width: 1400px) {
		margin-bottom: 50px;
	  }
}

.accordion{
	padding: 11px;
	border-style : solid;
	border-width: 1px;
	border-radius: 10px;
	font-size: 1.6rem;
    font-weight: 600;
	margin-bottom: 20px;
	margin-top: 10px;
	border-color: rgb(192, 192, 192);
}

.dropButton{
	color:#000000;
	float: right;
	cursor: pointer;
}

.accordionLabel{
	cursor: pointer;
}

.stockIndicator{
	background: #F0F1F7 0% 0% no-repeat padding-box;
	box-shadow: 0px 1px 4px #15223214;
	border-radius: 6px;
	opacity: 1;
	top: 205px;
	width: 250px;
	height: 70px;
	margin-top : 30px;
	margin-left : 40px;
	margin-right: 30px;
	margin-bottom : 30px;
	text-align: center;
}

.divContact{
	background: #f2f2f5 0% 0% no-repeat padding-box;
	border-radius: 6px;
	opacity: 1;
	width: 345px;
	height: 45px;
	text-align: center;
	margin-bottom: 15px;
	vertical-align: center;
	font-weight: 600;
	line-height: 40px;
}

.contactTitle{
	padding-bottom:  15px;
}

.importTitle{
	padding-top:20px;
	padding-bottom:  30px;
}

.icon{
	margin-right: 10px;
}

.icon-file-name {
    max-width: 300px;
    word-wrap: break-word;
    white-space: normal;
	margin-right: 10px;
}

.iconImport{
	margin-right: 10px;
}
.alerteButton{
	height: 30px;
	width: 200px;
	background-color: #f0efef;
	color: black;
	font-weight: 300;
	border-color: #000000;
	box-shadow: 0px 1px 4px #15223214;
}

.radius{
	border-radius: 10px;
	height: 25px;
}

.showMore{
	color:#000000;
	font-weight: 600;
	cursor: pointer;
	margin-top: 20px;
}

.buttonAddRound{
	width: 30px;
	height: 30px;
	border-radius: 20px;
	background-color: #f0efef;
	color: black;
	font-weight: 300;
	border-color: #000000;
	box-shadow: 0px 1px 4px #15223214;
}

.modalTitle{
	float: left;
}

.modalMarg{
	margin-top: 30px;
}

.modalSubmit{
	float: right;
}

.fontRed{
	color:red;
}

.fontOrange{
	color:orange;
}

.fontTeal{
	color:green;
}

.metrageDiv{
	background-color: #363740;
	display: flex;
	border-top-left-radius:60px ;
	border-bottom-left-radius: 60px;
	width: 450px;
	height: 200px;
}

.metrage{
	display: flex;
	justify-content: flex-end;
}

.fontMetrage{
	color:#d1d4d7;
}

.fontMetrage2{
	font-weight: bold;
	color:#d1d4d7;
	margin-left: 10px;
}

.legende{
	margin-top: 8px;
	margin-right: 8px;
	width: 10px;
	height: 10px;
	background-color: #CAB8FF;
}

.legende2{
	margin-top: 8px;
	margin-right: 8px;
	width: 10px;
	height: 10px;
	background-color: #C1FFD7;
}

.legende3{
	margin-top: 8px;
	margin-right: 8px;
	width: 10px;
	height: 10px;
	background-color: #B5DEFF;
}

.margBot{
	margin-bottom: 15px;
	width: 200px;
	height: 28px;
}

.searchSaler{
	margin-bottom: 15px;
	margin-right: 50px;
	height: 35px;
	width: 250px;
}

.inputTicket{
	margin-bottom: 15px;
	margin-right: 50px;
	height: 35px;
	width: 450px;
}

.tableMarg{
	margin-bottom: 40px;
}

.buttonCommand{
    margin-top: 30px;
    border-radius: 20px;
    height: 45px;
}

.iconSelection{
    border-radius: 100%;
    background-color: #efefef;
    height: 75px;
    width: 75px;
    border-style: none;
    margin-right: 15px;
    margin-bottom: 15px;
}

.iconSelection2{
	display: flex;
	flex-direction: column;
    border-radius: 100%;
    background-color: #ffffff;
    height: 125px;
    width: 125px;
    border-style: none;
    margin: 30px;
	margin-bottom: 70px;
}

.iconSizeTunnel{
	color: #0a5251;
	border-color: #0a5251;
    border-radius: 100%;
    background-color: rgb(254, 254, 254);
    height: 75px;
    width: 75px;
    border-width: 1.1px;
	border-style: solid;
    margin-right: 15px;
    margin-bottom: 15px;
	font: 15px Barlow;
}

.iconSizeInactiveTunnel{
	display: flex;
	align-items: center;
	justify-content: center;
	color: #0a5251;
	border-color: #0a5251;
    border-radius: 100%;
    background-color: rgb(254, 254, 254);
    height: 60px;
    width: 60px;
    border-width: 0.5px;
	border-style: solid;
    margin-right: 20px;
	margin-left: 15px;
	font: 12px Barlow;
}

.etapeActive{
    font-size: xx-large;
    font-weight: 600;
    margin-bottom: 20px;
    margin-top: 10px;
}

.etapeInactive{
    font-size: large;
    font-weight: 600;
    color:#c5c8cb;
    margin-bottom: 20px;
    margin-top: 10px;
	cursor: pointer;
}

.borderEtape{
    border-left: 1px solid ;
    padding-left: 20px;
}

.bulletActive{
    width: 10px;
    height: 10px;
    background-color: #000000;
    border-radius: 100%;
    position: relative;
    top: 32px;
    left: -10px;
}

.bulletInActive{
    width: 8px;
    height: 8px;
    background-color: #c5c8cb;
    border-radius: 100%;
    position: relative;
    top: 19px;
    left: -9px;
}

.inputNumber{
    width: 50px;
    border-radius: 75%;
    margin-right: 5px;
    text-align: center;
    font-weight: bold;
    /* Firefox */
    -moz-appearance: textfield;
 
    /* Chrome */
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin:0;
    }
 
    /* Opéra*/
    &::-o-inner-spin-button {
        -o-appearance: none;
        margin: 0; 
    }
}

.divShadow{
    font-weight: bold;
    background: #F0F1F7 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 4px #15223214;
    border-radius: 6px;
    opacity: 1;
    top: 205px;
    height: 60px;
    width: 460px;
    margin-top : 30px;
    margin-left : 30px;
    margin-right: 30px;
    margin-bottom : 30px;
    text-align: center;
    padding-left: 30px;
    padding-top: 15px;
}

.divShadow2{
	display: flex;
    font-weight: bold;
    background: #F0F1F7 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 4px #15223214;
    border-radius: 6px;
    opacity: 1;
    top: 205px;
    height: 60px;
    width: 460px;
	margin: 30px;
	align-items: center;
	padding-left: 20px ;
	padding-right: 20px;
}

.divShadow2:hover{
	display: flex;
    font-weight: bold;
    background: #e2e3e9 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 4px #15223214;
    border-radius: 6px;
    opacity: 1;
    top: 205px;
    height: 60px;
    width: 460px;
	margin: 30px;
	align-items: center;
	padding-left: 20px ;
	padding-right: 20px;
}
.inputButton{
    background-color: #000000;
    border-radius: 100%;
    width: 30px;
    box-shadow: 0px 1px 4px #15223214;
}

.right{
    float:right
}

.left{
    float:left
}
.basketButton{
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 15px;
}

.basketFont{
    font: bold 30px/46px Barlow;
}

.basketFontTotal{
    font: bold 40px/46px Barlow;
    margin-bottom: 15px;
}

.basketBox{
    display: flex;
    flex-direction: column;
    background: #F0F1F7 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 4px #15223214;
    border-radius: 6px;
    opacity: 1;
    top: 205px;
    width: 300px;
    margin-top : 30px;
    margin-left : 100px;
    margin-right: 30px;
    margin-bottom : 30px;
    text-align: center;
    justify-content: center;
    float: right;
	padding-bottom: 10px;
}

.basketResume{
	@media (max-width: 1500px) {
		display: flex;
		align-items: center;
		justify-content: center;
	  }
}

.basketItemBox{
    background: #F0F1F7 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 4px #15223214;
    border-radius: 6px;
    opacity: 1;
    top: 205px;
    width: 805px;
    margin-top : 10px;
    margin-left : 40px;
    margin-bottom : 10px;
	padding: 10px;
	align-items: center;
}

.basketRow{
	display: flex;
    flex-direction: row;
}

.basketRowCustom{
	display: flex;
    flex-direction: row;
	margin-bottom: 15px;
}

.rowProduct{
	display: flex;
    flex-direction: row;
	margin-bottom: 20px;
}

.basketDash{
    border-style: solid;
    border-top: #000000;
    border-left: #000000;
    border-right: #000000;
    margin-left: 50px;
    margin-right: 50px;

}

.basketIconTrash{
	margin-left: 10px;
}

.basketIcon{
    align-items: flex-start;
    margin-right: 30px;
}

.containerCol{
	display: flex;
	flex-direction: column;
}

.containerColCenter{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.containerRow{
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.statusOptions{
	width: 200px;
}
.containerRowGeneralBasket{
	display: flex;
	flex-direction: row;
	float: left;
	@media (max-width: 1500px) {
		flex-direction: column;
	  }
}

.containerInputBasket{
	display: flex;
	flex-direction: row;
	margin-right: 25px;
}

.inputBasket{
	margin-right: 5px;
	width: 60px;
	border-radius: 30%;
}

.inputBasketAccessory{
	width: 60px;
	border-radius: 30%;
	margin-right: 5px;
	margin-left: 30px;
}
.containerColBasket{
	display: flex;
	flex-direction: column;
	margin-left: 30px;
	margin-right: 30px;
	width: 200px;
}

.containerRowBasket{
	display: flex;
	flex-direction: row;
}

.fileIconBasket{
	width: 20px;
	margin-right: 10px;
}
.basketFontItem{
	font: bold 20px Barlow;
}

.basketFontItemModel{
	font: bold 17px Barlow;
	margin-bottom: 10px;
}

.basketFontRef{
	font: bold 15px Barlow;
}

.basketFontProductRef{
	font: 15px Barlow;
	margin-bottom: 10px;
}

.basketCustomText{
	margin-left: 10px;
	font: bold Barlow;
}

.basketFontRefAccessory{
	font: bold 15px Barlow;
	margin-left: 35px;
}

.loginForgotPass{
	margin-top: 10px;
	border-bottom: solid;
	border-width: 1px;
	font: 15px Barlow;
	cursor: pointer;
}

.inputPasswordLost{
	width: 300px;
}

.media{
	display: flex;
	margin: auto;
	max-width: 300px;
}

.centerTitle{
	display: flex;
	justify-content: center;
	padding-bottom: 20px;
}

.test{
	display: flex !important;
	background-color: #0099e0 !important;
	font-size: x-large !important;
	color:#000000 !important
}

.subTitleModal{
	padding-bottom: 20px;
}

.filterButton{
	margin-bottom: 20px;
}

.filterLabel{
	padding-bottom: 10px;
	margin-right: 15px;
	margin-left: 15px;
	/* font-weight: bold; */
}

.filterRow{
	display: flex;
	align-items: center;
}

.tunnelPicto{
	width: 123px;
	align-items: center;
	justify-content: center;	
}

.inactivePicto{
	width: 60px;
	margin-right: 20px;
	margin-left: 15px;
}

.labelPicto{
	width: 120px;
	margin-top: 17px;
}

.information-banner{
	display: flex;
	margin-left: 200px;
	margin-right: 200px;
	margin-top: 15px;
	margin-bottom: 30px;
	flex-direction: column;
	border-style: solid;
	border-width: 0.1px;
	border-radius: 10px;
	border-color: #000000;
}

.closeBannerButton{
	display: flex;
	justify-content: end;
	align-items: flex-end;
	margin: 5px;
	margin-right: 10px;
	cursor: pointer;
	font-weight: bold;
}

.bannerMessage{
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 30px;
	font: bold 20px/30px Barlow;
}

.bannerPageCard{
	display: flex;
	justify-content: center;
	margin-top: 30px;
	width: 500px;
}

.submitParam{
	float: right;
	margin-top: 40px;
}

.checkBoxBanner{
	margin-bottom: 20px;
}

.clientNote{
	width: 500px;
}

.basketPicto{
	width: 45px;
	margin-right: 10px;
	margin-left: 15px;
}

.fileBasketContainer{
	align-items: flex-start;
	width: 330px;
	margin-right: 10px;
}

.fileNameBasket{
	align-items:start;
	margin-right: 15px;
	width: 240px;
}


.basketListTitle{
	padding-top: 25px;
	padding-bottom: 25px;
}

.linkVanilla{
	color: #000000;
}

.addPriceButton{
	display: flex;
	justify-content: center;
	align-items: center;
	align-self: center;
}

.formDiv{
	display: flex;
	flex-direction: column;

}

.divInfo{
	cursor: pointer;
}

.confirmationModal{
	padding: 20px;
}

.informationModal{
	display: flex;
	margin: auto;
	justify-content: center;
	align-items: center;
}

.infoList{
	list-style-type : none;
	width: 800px;
}

.marginAutoLeft{
	margin-left: auto;
}

.lds-roller {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
  }
  .lds-roller div {
	animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	transform-origin: 40px 40px;
  }
  .lds-roller div:after {
	content: " ";
	display: block;
	position: absolute;
	width: 7px;
	height: 7px;
	border-radius: 50%;
	background: #2a2e36;
	margin: -4px 0 0 -4px;
  }
  .lds-roller div:nth-child(1) {
	animation-delay: -0.036s;
  }
  .lds-roller div:nth-child(1):after {
	top: 63px;
	left: 63px;
  }
  .lds-roller div:nth-child(2) {
	animation-delay: -0.072s;
  }
  .lds-roller div:nth-child(2):after {
	top: 68px;
	left: 56px;
  }
  .lds-roller div:nth-child(3) {
	animation-delay: -0.108s;
  }
  .lds-roller div:nth-child(3):after {
	top: 71px;
	left: 48px;
  }
  .lds-roller div:nth-child(4) {
	animation-delay: -0.144s;
  }
  .lds-roller div:nth-child(4):after {
	top: 72px;
	left: 40px;
  }
  .lds-roller div:nth-child(5) {
	animation-delay: -0.18s;
  }
  .lds-roller div:nth-child(5):after {
	top: 71px;
	left: 32px;
  }
  .lds-roller div:nth-child(6) {
	animation-delay: -0.216s;
  }
  .lds-roller div:nth-child(6):after {
	top: 68px;
	left: 24px;
  }
  .lds-roller div:nth-child(7) {
	animation-delay: -0.252s;
  }
  .lds-roller div:nth-child(7):after {
	top: 63px;
	left: 17px;
  }
  .lds-roller div:nth-child(8) {
	animation-delay: -0.288s;
  }
  .lds-roller div:nth-child(8):after {
	top: 56px;
	left: 12px;
  }
  @keyframes lds-roller {
	0% {
	  transform: rotate(0deg);
	}
	100% {
	  transform: rotate(360deg);
	}
  }

.basketAccessory{
	display: flex;
	flex-direction: row;
	margin-left: 360px;
}

.textAreaCommand{
	height: 200px;
}
  
.progressMedia{
	margin-top: 30px;
	margin-bottom: 20px;
}

.basketInfo{
	background-color: rgba(255, 0, 0, 0.755);
	border-radius: 100%;
	width: 25px;
	margin-left: 5px;
	color: white;
}

.imageMarque{
	height: 80px;
	cursor: pointer;
	&:hover{
		height: 90px;
	}
}

.divMarqueCatalogue{
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 100px;
}

.imageProduct{
	height: 200px;
}

.productCard{
	display: flex;
	width: 250px;
	height: 375px;
	border-radius: 10px;
	align-items: center;
	flex-direction: column;
	margin: 20px;
	box-shadow: 0px 1px 4px #15223214;
	cursor: pointer;
}

.productCardType{
	border-top: #000000;
	font: bold 20px/30px Barlow;
	margin-top: 10px;
}

.productCardRef{
	font: 12px/30px Barlow;
}

.productCardModel{
	font: 16px/30px Barlow;
}

.productCardPrice{
	font: bold 15px/30px Barlow;
	margin-top: 10px;
}

.productCardSeparator{
	display: flex;
	border-style: solid;
	border-width: 0.1px;
	width: 200px;
	border-color: #717171c4;
}

.imageProductInfo{
	height: 450px;
}

.productInfoPrice{
	font: bold 20px/30px Barlow;
	margin-bottom: 10px;
}

.productInfoType{
	margin-top: 50px;
	font: bold 30px/30px Barlow;
	margin-bottom: 20px;
}

.productInfoModel{
	font: bold 20px/10px Barlow;
	margin-bottom: 40px;
}

.productInfoCustomText{
	margin-top: 20px;
	margin-bottom: 30px;
}

.productInfoColors{
	margin-top: 20px;
	margin-bottom: 10px;
}

.colorList{
	border-radius: 100%;
	border-style: solid;
	border-width: 0.01px;
	border-color: black;
	width: 30px;
	height: 30px;
	margin: 10px;
	cursor: pointer;
}

.colorListSelect{
	border-radius: 100%;
	border-style: solid;
	border-width: 2px;
	border-color: black;
	width: 30px;
	height: 30px;
	margin: 10px;
	cursor: pointer;
}

.colorBasket{
	border-radius: 100%;
	width: 30px;
	height: 30px;
	margin-left: 10px;
}
.productInfoLabelCustom{
	margin-right: 15px;
	margin-left: 15px;
}

.visualBasketProduct{
	height: 100px;
}

.productInfoPriceDiv{
	margin-top: 30px;
}

.addCustomFile{
	margin-top: 50px;
	padding: 20px;
	border-width: 0.1px;
	border-radius: 2%;
	box-shadow: 0px 1px 4px #15223214;
}

.infoCustomFile{
	margin-top: 20px;
}

.customMediaName{
	display: flex;
	align-items: center;
	justify-content: center;
}

.imgPictoList{
	max-height: 70px;
	max-width: 70px;
}

.pictoList{
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100px;
	height: 100px;
	border-style: solid;
	border-radius: 100%;
	border-width: 0.1px;
	margin: 10px;
	cursor: pointer;
}

.pictoListSelect{
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100px;
	height: 100px;
	border-style: solid;
	border-radius: 100%;
	border-width: 2px;
	margin: 10px;
	cursor: pointer;
}

.pictoBasket{
	border-radius: 100%;
	width: 50px;
	height: 50px;
	margin-left: 10px;
}

.widthInfo{
	margin-bottom: 20px;
}


.inputMediaDB{
	width: 500px;
}


.switchInput[type=checkbox]{
  height: 0;
  width: 0;
  visibility: hidden;
}

.switchLabel{
  cursor: pointer;
  text-indent: -9999px;
  width: 50px;
  height: 25px;
  background: grey;
  display: block;
  border-radius: 100px;
  position: relative;
}

.switchLabel:after {
  content: '';
  position: absolute;
  top: 1.25px;
  left: 1.25px;
  width: 22.5px;
  height: 22.5px;
  background: #fff;
  border-radius: 90px;
  transition: 0.3s;
}

.switchInput:checked + .switchLabel {
  background: #bada55;
}

.switchInput:checked + .switchLabel:after {
  left: calc(100% - 5px);
  transform: translateX(-100%);
}

.switchLabel:active:after {
  width: 130px;
}

.imgDropbox{
	width: 1000px;
}

.stepMedia{
	padding-bottom: 20px;
	padding-top: 20px;
}

.checkboxStatus{
	margin-right: 10px;
}

.selectInput{
	height: 50px;
	width: 300px;
}

.errorCollapse{
	border-color: rgb(220, 7, 7);
}

.errorFormMessage{
	font-size: 12px;
	font-weight:400;
	color: rgb(220, 7, 7);
}

.mainCardImportDB{
	margin-top: 20px;
	padding: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.importDbInfo{
	margin-top: 30px;
}

.listInfoImport{
	margin-bottom: 10px;
}

.importInfoDiv{
	margin: 20px;
}

.inputSheetName{
	margin-top: 20px;
	margin-bottom: 30px;
	width: 400px;
}

.fileNameImport{
	margin-left: 10px;
	margin-right: 10px;
}

.labelImport{
	font: 15px/30px Barlow;
	margin-right: 10px;
}

.pennyLaneButton{
	size: 20px;
}

.shippingLabel{
	font: bold 17px/22px Barlow;
	margin-right: 10px;
}

.wishWarning{
	color: red;
	font: bold 17px/22px Barlow;
}

.commentTitle{
	font: bold 17px/22px Barlow;
	margin-bottom: 10px;
}

.comment{
	margin-left: 40px;
}

