.loginBody form.create-password-form {
  width: auto;
  justify-content: center;
}

form.create-password-form .password-section {
  width: 300px;
  margin: auto;
}

p.cgu-container {
  max-width: 600px;
  padding: 20px 15px;
  height: 400px;
  overflow: auto;
  margin: 30px 0;
}

form.create-password-form .btn {
  margin: 50px 0;
}

form.create-password-form h3 {
  margin-top: 30px;
  text-align: center;
}

.cgu-field-container {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.cgu-field-container div {
  margin-left: 10px;
}

.cgu-btn {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.cgu-btn:hover {
}
