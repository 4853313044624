.table-outline {
  border: 1px solid $table-border-color;

  td {
    vertical-align: middle;
  }
}

.table-align-middle {

  td {
    vertical-align: middle;
  }
}

.table-clear {
  td {
    border: 0;
  }
}

/* OVERRIDE REACTTABLE LAYOUT */

.ReactTable {
  border: none !important;
  overflow: visible !important;
  padding-bottom: 60px;

  .rt-table {
    overflow: visible !important;
  }

  .rt-thead {
    border-radius: 10px;
    height: 50px;
    background-color: $tertiary;
    box-shadow: none !important;

    &.-filters {
      display: none !important;
    }

    .rt-td {
      border: none !important;
      font-weight: bold;
    }

    .rt-th  {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      font-weight: 600;
      border: none !important;

      &:first-of-type {
        padding-left: 20px;
      }
      
    }
  }

  .rt-tbody {
    overflow: hidden !important;

    .rt-tr-group {
      height: 65px;

      .rt-tr {
        position: relative;

        &:hover {
          background-color: $hover !important;

          &:before {
            content:'';
            display: flex;
            position: absolute;
            height: 100%;
            width: 100%;
            left: -100%;
            top: 0;
            background-color: $hover;
          }

          &:after {
            content:'';
            display: flex;
            position: absolute;
            height: 100%;
            width: 100%;
            right: -100%;
            top: 0;
            background-color: $hover;
          }
        }
        .rt-td {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          border-right: none !important;

          &:first-of-type {
            padding-left: 20px;
          }
          &:last-of-type {
            align-items: flex-end;
          }
        }
      }
    }
  }

  .pagination-bottom {
    position: absolute;
    top: -70px;
    right: 0;

    .-pagination {
      box-shadow: none !important;
      border: none !important;

      .-previous {
        order: 0;
        flex: 0 1 auto;
        width: 32px;
        margin-right: 15px;
        
        .-btn {
          color: white !important;
          font-weight: 600;
          width: 32px;
          height: 32px;
          border-radius: 50%;
          background-color: $secondary;
          overflow: hidden;
        }
        
      }

      .-next {
        order: 1;
        flex: 0 1 auto;
        width: 32px;
        margin-right: 15px;
        
        .-btn {
          color: white !important;
          font-weight: 600;
          width: 32px;
          height: 32px;
          border-radius: 50%;
          background-color: $secondary;
          overflow: hidden;
        }
        
      }

      .-center {
        order: 2;
      }

    }
  }
}