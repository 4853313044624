/* table-auto max-w-full align-middle border-b border-gray-200 shadow rounded-b-lg */

.table-container {
  table-layout: auto;
  max-width: 100%;
  vertical-align: middle;
  margin-bottom: 50px;
}

/* w-full max-w-full min-w-full */

.table-headers {
  width: 100%;
  max-width: 100%;
  min-width: 100%;
}

.table-header-group {
  border-radius: 10px;
  height: 50px;
  background-color: #f0f1f7;
  box-shadow: none !important;
}

/* max-w-full min-w-full bg-gray-100 border-b border-gray-200 rounded-t-lg */

.table-header-group {
  max-width: 100%;
  min-width: 100%;
}

/* flex-grow overflow-hidden flex justify-between items-center
                      px-5 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500
                      uppercase */

.table-header {
  flex-grow: 1;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 5px 3px; */
  text-align: left;
}

.table-header.cursor-pointer {
  cursor: pointer;
}

/* text-xs font-semibold w-full h-full flex items-center */
.table-header-text {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 5px;
  font-weight: 600;
  color: black;
}

.table-header:first-of-type .table-header-text {
  padding-left: 20px;
}

.resizer {
  display: inline-block;
  width: 15px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(50%);
  z-index: 1;
  touch-action: none;
}

.table-row{
  height: auto !important;
}
.table-row:hover {
  background-color: #becd0026;
}

/* flex-grow pl-6 py-3 text-xs overflow-hidden */

.table-cell-container {
  flex-grow: 1;
  padding: 7px 5px;
  overflow: hidden;
}

.table-cell-container:first-of-type {
  padding-left: 20px;
}

/* flex items-center h-full */

.table-cell-content {
  display: flex;
  align-items: center;
  height: 100%;
  color: black;
}

/* text-center text-slate-500 p-8 */

.table-no-data {
  text-align: center;
  padding: 10;
}

/* w-full h-full py-28 */

.table-loader {
  width: 100%;
  height: 100%;
  padding: 15px 0;
}
