.pagination-container {
  display: flex;
  justify-content: right;
  position: absolute;
  top: -70px;
  right: 0;
}

.pagination-toolbar {
  display: flex;
  padding: 10px 0;
  color: black;
  align-items: center;
}

.pagination-toolbar input,
.pagination-toolbar select {
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 5px 7px;
  font-size: inherit;
  border-radius: 3px;
  text-align: center;
}

.pagination-toolbar input {
  width: 70px;
}

.pagination-toolbar select {
  width: 100px;
  text-align: left;
}

.pagination-button {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #363740;
  color: white;
  font-weight: 600;
  padding-top: 2px;
  border: none;
  margin: 0 10px;
}

.pagination-button.disabled {
  opacity: 0.5;
  cursor: default;
}

.pagination-button > div {
  font-size: 1.5rem !important;
}

button.pagination-button:focus {
  outline: none;
}
